/* custom-form.css */

/* Change the size of the form label */
.ant-form-item-label label {
    font-size: 14px;
}

.ant-form {
    color: #242424 !important;
}

/* custom-select.css */

/* Change the font size of the dropdown menu items */
.ant-select-dropdown-menu-item {
    font-size: 14px;
    color: #000 !important;
    /* Replace with your desired font size */
}

.ant-dropdown-menu {
    border-radius: 3px !important;
}

/* custom-antd.css */

/* custom-antd.css */

/* Ant Design 3 */
/* Input */
.input,
.select-selection,
.input-number-input-wrap,
.cascader-input {
    border-radius: wpx !important;
}

/* Textarea */
.textarea,
.select-selection.select-selection-disabled,
.cascader-input {
    border-radius: 2px !important;
}

/* Date picker */
.date-picker-input input {
    border-radius: 2px !important;
}

/* Time picker */
.time-picker-input {
    border-radius: 2px !important;
}

/* Range picker */
.range-picker-input {
    border-radius: 2px !important;
}

/* Ant Design 5 */
/* Input */
.ant-input,
.ant-select-selector,
.ant-input-number-input-wrap,
.ant-cascader-input {
    border-radius: 2px !important;
    border-color: #939393;
}

/* Textarea */
.ant-input-textarea,
.ant-select-selection,
.ant-input-number-input,
.ant-input-number,
.ant-select-dropdown,
.ant-select-selector.ant-select-selector-disabled,
.ant-cascader-input {
    border-radius: 2px !important;
}

.ant-select-selection__placeholder {
    font-size: 13px !important;
    color: #767575;
}

.ant-input-textarea,
.ant-select-selection,
.ant-input-number-input,
.ant-input-number,
.ant-select-dropdown,
.ant-select-selector.ant-select-selector-disabled,
.ant-cascader-input {
    border-radius: 2px !important;
    border-color: #939393;

}

/* Date picker */
.ant-picker-input input {
    border-radius: 1px !important;
}

/* Time picker */
.ant-time-picker-input {
    border-radius: 1px !important;
}

/* Range picker */
.ant-picker-range input {
    border-radius: 1px !important;
}

.ant-tag {
    border-radius: 1.5px !important;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
}

/* Notification container */
.ant-notification {
    border-radius: 3px !important;
    /* Adjust the value as needed */
    padding: 12px !important;
    z-index: 999999 !important;
}


.ant-radio-inner {
    border-color: #1b77af !important;
    position: relative !important;
}

.ant-radio-inner::after {
    width: 70% !important;
    height: 70% !important;
    margin: 0;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 50% !important;
    background-color: #1B77AF !important;
}

@media screen and (min-width: 1080px) {
    .ant-radio-inner::after {
        width: 66% !important;
        height: 66% !important;
        margin: 0;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-48%, -52%) !important;
    }
}

@media screen and (min-width: 1260px) {
    .ant-radio-inner::after {
        width: 70% !important;
        height: 70% !important;
        margin: 0;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

@media screen and (min-width: 1440px) {
    .ant-radio-inner::after {
        width: 66% !important;
        height: 66% !important;
        margin: 0;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

@media screen and (min-width: 1720px) {
    .ant-radio-inner::after {
        width: 70% !important;
        height: 70% !important;
        margin: 0;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-48%, -52%) !important;
    }
}

@media screen and (min-width: 1920px) {
    .ant-radio-inner::after {
        width: 70% !important;
        height: 70% !important;
        margin: 0;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-53%, -50%) !important;
    }
}

@media screen and (min-width: 2560px) {
    .ant-radio-inner::after {
        width: 75% !important;
        height: 75% !important;
        margin: 0;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}


@media screen and (min-width: 3840px) {
    .ant-radio-inner::after {
        width: 75% !important;
        height: 75% !important;
        margin: 0;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

.ant-checkbox-inner {
    border-color: #1b77af !important;
    border-radius: 4px;
    width: 17px !important;
    height: 16px !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 1px solid #fff;
    margin-left: 0.5px !important;
    margin-top: -1px !important;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(40deg) scale(1) translate(-50%, -50%) !important;
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%) !important;
    transform: rotate(40deg) scale(1) translate(-50%, -50%) !important;
    opacity: 1;
    left: 24% !important;
    width: 4.414286px !important;
    height: 9.542857px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1b77af !important;
}

.read-only-antd-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #939393 !important;
    color: #fff !important;
    cursor: default !important;
}

.read-only-antd-checkbox .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff !important;
}

.read-only-antd-checkbox .ant-checkbox-disabled+span {
    color: black !important;
    cursor: default !important;
}

/* .ant-checkbox-checked .ant-checkbox-inner::after {
    border: none !important;
} */

.read-only-antd-checkbox .ant-checkbox-inner {
    border-color: #939393 !important;
    border-radius: 4px;
    width: 17px !important;
    color: #fff !important;
    cursor: default !important;
    height: 16px !important;
}

.ant-btn {
    border-radius: 2px !important;
}

/* .neo-option-plain {
    padding: 0px 16px !important;
} */

.exportSelect>.neo-option-plain {
    padding: 8px 16px !important;
}

li.download-none>.neo-option-plain {
    display: none !important;
}

#inline-checkbox-group-label {
    color: #242424 !important;
}

.export-menu>.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background: #F1F1F1 !important;
}

.export-download-button.neo-btn.neo-btn--default.neo-btn-secondary.neo-btn-secondary--default.neo-icon-chevron-down.ant-dropdown-trigger {
    border-color: #939393 !important;
    color: #242424 !important;
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
}

.neo-btn {
    z-index: 998 !important;
}

.neo-dropdown__content {
    z-index: 999 !important;
}

.neo-tooltip__content {
    z-index: 999 !important;
}

.export-download-button.neo-btn.neo-btn--default.neo-btn-secondary.neo-btn-secondary--default.neo-icon-chevron-down.ant-dropdown-trigger::before {
    margin-right: 0px !important;
}

.export-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
    outline-offset: 1px !important;
    outline: 1px auto #1B77AF !important;
}

.ant-checkbox-checked::after {
    transition: none;
}

.remove-margin-wrapper.ant-checkbox-wrapper {
    margin-left: 0px !important;
}

.ant-checkbox-wrapper {
    transition: none;
}

.ant-select-single.ant-select-lg {
    height: 36px !important;
    border: 1px solid #939393 !important;
    border-radius: 2px;
}

.ant-select-dropdown .ant-select-item {
    padding: 8px 16px !important;
    color: #000;
    font-size: 14px !important;
    min-height: 36px !important;
    font-weight: 400;
}


.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: white !important;
    font-weight: 400;
}

.ant-select-dropdown-menu-item:hover {
    background-color: #F1F1F1 !important;
    /* Set your desired hover background color */
}

.ant-select-single.ant-select-lg .ant-select-selector {
    font-size: 14px !important;
}

.ant-time-picker-large .ant-time-picker-input {
    height: 36px;
    color: #242424 !important;
    padding: 6px 11px;
    font-size: 14px !important;
}

.ant-form-item-required::before {
    color: #b51418 !important;
    font-size: 12px !important;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
    color: #b51418;
    font-size: 12px;
    display: flex
}

.neo-form .neo-form-control {
    margin-bottom: 0px !important;
}

.neo-table__actions {
    display: none !important;
}

.neo-table td:contains("No Data Available") {
    text-align: center !important;
}

.neo-table tr:hover {
    border-bottom: 1px solid #fff !important;
}

.neo-table tr:not(.disabled):hover,
.neo-table tr:not(.disabled):focus-within {
    background-color: #F1F1F1 !important;
}

.neo-table th {
    text-align: left !important;
}

.neo-table td span[class*=neo-icon-]:before {
    font-size: 20px !important;
}

.neo-table {
    border-collapse: collapse !important;
    empty-cells: show !important;
    background-color: #0000 !important;
    width: 100% !important;
    max-width: 100% !important;
}

.neo-table tr {
    border-bottom: 1px solid #fff !important;
    background-color: #fff !important;
}

.neo-table th {
    text-align: left !important;
    color: #000 !important;
    vertical-align: middle !important;
    background-color: #fff !important;
    letter-spacing: 0 !important;
    border-bottom: 1px solid #C9C9C9 !important;
    border-top: 1px solid #C9C9C9 !important;
    min-height: 44px !important;
    padding: 12px 16px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}

.neo-table td {
    color: #000 !important;
    vertical-align: middle !important;
    padding: 12px 16px !important;
}

.input-antd {
    border: 1px solid #939393;
    border-radius: 2px;
    flex-direction: row;
    justify-content: space-between;
    min-height: 36px;
    display: flex;
}

.input-antd.ant-input-lg {
    height: 36px !important;
    font-size: 14px !important;
    color: #242424 !important;
    font-family: 'Noto Sans', sans-serif !important;
    font-weight: 400 !important;
}

.input-antd.ant-input:focus,
.input-antd.ant-input:active {
    border: 2px solid #1B77AF !important;
    box-shadow: none !important;
}

.input-antd.ant-input:hover {
    box-shadow: none !important;
    border: 1px solid #939393;
    border-radius: 2px;
}

.ant-checkbox-wrapper {
    margin-left: 8px !important;
}

.remove-margin-checkbox.ant-checkbox-wrapper {
    margin: 0 !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    font-size: 18px !important;
    margin-top: 8px !important;
}

span.ant-dropdown-menu-submenu-expand-icon.ant-dropdown-menu-submenu-arrow {
    margin-top: -29px !important;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light>li:nth-child(2)>div>span.ant-dropdown-menu-submenu-expand-icon.ant-dropdown-menu-submenu-arrow {
    margin-top: 0px !important;
}

.contact-us-btn-1.neo-btn-primary--default {
    background-color: #68CFFE !important;
    color: black !important;
    background-image: none !important;
}

.contact-us-btn-2.neo-btn-secondary--default {
    background-color: black !important;
    color: #68CFFE !important;
    background-image: none !important;
}

.apexcharts-legend-text {
    color: rgb(55, 61, 63) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Noto-Sans, sans-serif !important;
}

.apexcharts-text.apexcharts-xaxis-label,
.apexcharts-text.apexcharts-yaxis-label {
    color: rgb(55, 61, 63) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Noto-Sans, sans-serif !important;
}

.dashboard-demo-stats :where(.css-dev-only-do-not-override-12jzuas).ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
:where(.css-dev-only-do-not-override-12jzuas).ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #C9C9C9;
}

.dashboard-demo-stats .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1B77AF;
}

.dashboard-demo-stats .neo-tabs__nav {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #C9C9C9;
}

.dashboard-demo-stats .neo-tabs__item .neo-tabs__item--active,
.dashboard-demo-stats .neo-tabs__item {
    display: grid;
    width: 100%;
    text-align: center;
}

.demo-stats-details-modal .neo-modal__content .neo-modal__footer .neo-btn:first-child {
    display: none;
}

.dashboard-demo-stats {
    margin-top: 8px;
}

.dashboard-demo-stats .neo-tabs__container--active {
    margin-top: 8px;
}

.apexcharts-menu-item.exportCSV {
    display: none !important;
}

.neo-chips__item,
.neo-chip {
    white-space: normal !important;
}

.remove-padding-style .neo-multiselect ul li {
    padding: 0 16px !important;
}

div.neo-multiselect button.neo-multiselect__header {
    order: 0 !important;
}

.ant-popover-title {
    text-align: center;
}

.neo-modal .neo-modal__header h4 {
    font-size: 19px !important;
}

.mbsc-ios.mbsc-schedule-event-background {
    opacity: .5 !important;
}

#templated-pagination {
    display: flex !important;
    justify-content: space-between !important;
}

.ant-tag-blue {
    color: #242424;
    background: #e7f4fb;
    border-color: #4c94ed;
    border-radius: 4px !important;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background: #1B77AF;
}

.ant-calendar-selected-day .ant-calendar-date {
    color: #fff;
    background: #1B77AF;
}

.ant-drawer-header,
.ant-drawer-footer {
    z-index: 109901991089 !important;
}

.neo-table>thead {
    position: relative !important;
    z-index: 1 !important;
}

.neo-notification--alert {
    border: 1px solid #da291c !important;
    border-top-width: .375rem !important;
    background-color: #fdeded7f !important;
}

.neo-multiselect__content.neo-set-keyboard-focus {
    z-index: 1000 !important;
}

.tooltip-default {
    background-color: lightgray !important;
    color: #000 !important;
}

.custom-tooltip-1 {
    background: lightgray !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    color: #fff !important;
    padding: 8px 16px !important;
    border-radius: 20px !important;
    z-index: 1000 !important;
    min-width: 200px;
}

.custom-tooltip-1 p {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14px !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #1B77AF !important;
}

.globe-map-tabs>.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
    visibility: visible !important;
}

path[data-name="FK"] {
    display: none;
}

.remove-btn-index.neo-btn {
    z-index: 1 !important;
}

.neo-table {
    table-layout: auto !important;
}

.ant-notification .ant-notification-topRight {
    display: none !important;
}

.neo-table th button {
    margin-left: -4px !important;
}