.home-page-main-image {
    display: block;
    width: 100%;
    height: 740px;
    background: url("../src/assets/images/landing-page/landing-page-1.jpg") lightgray 50% / cover no-repeat;
}

.image-container {
    width: 100%;
    height: auto;
    position: relative;
    display: inline-block;
}

.img-home-page-1 {
    background: url("../src/assets/images/landing-page/catalog-1.png") white 50% / cover no-repeat;
    width: 890px;
    height: 636px;
}

.home-page-frame {
    position: relative;
    width: 100%;
    left: 0px;
    bottom: 107px;
}

.img-2-home-content {
    gap: 16px;
    padding-top: 86px;
    width: 375px;
}

.sales-img-cont-1 {
    display: flex;
    width: 737px;
    height: 393px;
    padding: 0px 50px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.homepage-section-title {
    font-size: 32px;
    margin-bottom: 16px;
    width: 375px;
    font-weight: 700;
    letter-spacing: 0.64px;
    line-height: 48px;
    font-family: 'Noto Sans', sans-serif;
}

.homepage-section-img {
    max-width: 90%;
    padding: 40px 0 20px 0;
}

.home-page .ant-divider-horizontal {
    height: 2px !important;
}

.home-page {
    height: 100% !important;
    overflow-y: auto;
}

.catalog-6 {
    display: flex;
    justify-content: center !important;
    flex-direction: column !important
}

.homepage-section-desc {
    font-size: 16px;
    width: 375px;
    line-height: 24px;
    letter-spacing: 0.32px;
    font-weight: 400;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-main-container {
    display: flex;
    padding-right: 10px;
    /* height: 100%; */
    flex-flow: row wrap;
    flex-wrap: nowrap;
}

.admin-dashboard-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.admin-dashboard-row-1-of-4-div {
    border-width: 0px 1.5px 0px 0px;
    border-style: solid;
    border-color: #f0f2f5;
    /* height: 220px !important; */

}

.admin-dashboard-row-2-of-4-div {
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: #f0f2f5;

}

.admin-dashboard-row-4-of-4-div {
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: #f0f2f5;

}

.admin-dashboard-row-3-of-4-div {
    border-width: 0px 1.5px 0px 0px;
    border-style: solid;
    border-color: #f0f2f5;
}

.dashboard-left-container {
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 8px;
    border-color: #f0f2f5;
}

.dashboard-bottom-container {
    border-width: 8px 0px 0px 0px;
    border-style: solid;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-color: #f0f2f5;
}

.dashboard-left-container:after {
    content: "";
    position: absolute;
    top: 0;
    right: -8px;
    /* Should match the margin-right value above */
    bottom: 0;
    width: 8px;
    /* Should match the margin-right value above */
    background-color: #f0f2f5;
    /* Change this to your desired margin color */
}


.admin-dashB-stats-div {
    width: 170px;
    height: 90px;
    display: flex;
    flex-direction: column;
    margin: 5px !important;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: transform 0.2s;
    /* Add a transition for smooth scaling */
}

.admin-dashB-stats-div-link:hover {
    transform: scale(1.05);
    /* Zoom in by 10% on hover */
}

.first-row {
    flex: 3;
    /* Takes 3/4 of the height */
    font-weight: bold;
    font-size: 40px;
    padding: 0 5px 0 0;
    text-align: right;

}

.second-row {
    flex: 1;
    /* Takes 1/4 of the height */
    text-align: right;
    /* font-weight: bold; */
    padding: 0 0px 5px 0;
    padding-right: 10px;
    /* Add some right padding for "Demo Requests" */
}


.dashboard-right-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 8px;
    margin-top: -18px;
}

.dashboard-card-grid-height-left {
    /* height: 100% !important; */
    background: white;
}

.dashboard-request-statistics {
    height: 200px !important;
}

.request-statistics-table .ant-table-tbody>tr>td {
    padding-top: 5px !important;
    /* Adjust this value as needed for top padding */
    padding-bottom: 5px !important;
    /* Adjust this value as needed for bottom padding */
}

.dashboard-used-demo-div {
    margin-bottom: 50px !important;
}

.dashboard-card-grid-height-right {
    background: white;
    padding-left: 10px;
    margin-right: 0vmax;
    padding-bottom: 15px;
    /* height: 100% !important; */
}

.dashboard-schedules-category-heading {
    font-size: 19px;
    font-weight: 400;
    color: #242424 !important;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    padding-bottom: 16px;
    padding-top: 16px;
}

.dashboard-category-heading-with-extra {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.dashboard-category-extra {
    margin-left: auto;
    line-height: 40px;
    font-size: 12px;
    letter-spacing: 0em;
    cursor: pointer;
    color: #1B77AF
}

.dashboard-schedules-category-heading .ant-select-sm {
    width: 150px !important;
    font-weight: 500;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-schedules-demo-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B77AF !important;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-maintenance-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #242424 !important;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-card-details {
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-schedule-card {
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: #C9C9C9;
    margin-left: 3px !important;
    padding-left: 10px;
}

.dashboard-schedule-card-details {
    line-height: 18px;
    font-size: 12px;
}

.neo-chip {
    padding: 2px 8px !important;
    /* height: 23px !important;
    max-height: 23px !important; */
    min-height: 20px !important;
}

.dashboard-card-status-tag {
    float: right;
}

.dashboard-card-status-tag-column {
    padding-right: 0px !important;
    margin-right: 0px !important;
    float: right;
}

.dashboard-card-status-tag .ant-tag {
    border-radius: 2px;
    color: rgb(42, 39, 39);
}

.admin-schedule-tag .ant-tag {
    margin: 0;
    border-radius: 2px;
    color: rgb(42, 39, 39);
    border: 1px solid #d3d3d3
}

.dashboard-schedule-divider.ant-divider-horizontal {
    margin: 12px 0 !important;
}

.dashboard-no-schedules-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dashboard-no-schedules {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: auto;
    height: 88px;
    text-align: center !important;
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    color: black;
    border: 1px solid #d3d3d3;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin: 10px;
    font-family: 'Noto Sans', sans-serif;
}

.search-no-results {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: -10px;
    margin: -10px;
    width: auto;
    height: 200px;
    text-align: center !important;
    justify-content: center;
    flex-direction: column;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 20px; */
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-no-upcoming-schedules {
    box-sizing: border-box;
    text-align: center !important;
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    display: flex;
    align-items: center;

    margin: 10px;
    padding: 0px;
    gap: 10px;
    width: auto;
    height: 400px;
    color: #5E5E5E;
    border: 1px solid #d3d3d3;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-empty {
    box-sizing: border-box;
    text-align: center !important;
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 0px;
    gap: 10px;
    width: auto;
    height: 99%;
    background-color: #eaeaea42;
    border: 1px solid #d3d3d3;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Noto Sans', sans-serif;
}

.no-data {
    box-sizing: border-box;
    text-align: center !important;
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 0px;
    gap: 10px;
    color: #5E5E5E !important;
    border: 1px solid #d3d3d3;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.sm-div {
    width: auto;
    height: 200px;
}

.exsm-div {
    width: auto;
    height: 100px;
}


.md-div {
    width: auto;
    height: 300px;
}

.lg-div {
    width: auto;
    height: 400px;
}

.no-data-result .ant-result-icon>.anticon {
    size: 20px;
}

.no-data-result .ant-result-title {
    size: 12px !important;
}

.dashboard-no-schedules .neo-icon-info {
    color: #242424 !important;
}

.ant-spin {
    color: #757474
}

.dashboard-schedule-more-options {
    float: right;
    margin-top: -5px !important;
}

.notification-dropdown-card {
    padding: 10px 20px;
    overflow-y: hidden;
    min-height: 300px;
    max-height: 80vh;
    overflow-x: hidden !important;
}

.push-notification-dropdown-list {
    overflow-x: hidden !important;
}

.dashboard-news-container {
    height: auto;
    margin-right: 15px;
    block-size: fit-content !important;
    overflow-y: scroll;
    padding-left: 5px;
}

.dashboard-news-div {
    max-height: 35vh;
    overflow-y: auto;
    padding-top: 0px !important;
    padding-left: 0px !important;
}

.dashboard-news-card-row {
    border-width: 0px 0px 0px 5px;
    border-style: solid;
    border-color: #1B77AF;
    margin-left: 3px !important;
    padding-left: 10px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.dashboard-news-card-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #242424 !important;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-news-card-title:hover {
    color: #1B77AF !important;
}

.dashboard-news-divider.ant-divider-horizontal {
    margin: 12px 0 !important;
}

.news-card-view-all {
    float: right;
    line-height: 40px;
    font-size: 12px;
    letter-spacing: 0em;
    color: #1B77AF
}

.dashboard-news-card-row .ant-avatar {
    width: 60px !important;
    height: 60px;
}

.dashboard-most-used-demo-card {
    background-color: #F1F1F1;
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 8px;
}

.dashboard-most-used-demo-card-solution-type {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #242424;
    padding-bottom: 0px;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-most-used-demo-title {
    font-size: 14px !important;
    font-weight: 600;
    color: #242424;
    line-height: 24px;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-most-used-button-group {
    padding-top: 10px;
}

.dashboard-most-used-button-group .neo-btn--compact {
    min-height: 24px;
    padding: 0px 8px;
    font-size: 12px;
}

.ez-dashboard-start-button .neo-btn--compact {
    min-height: 24px;
    padding: 0px 8px;
    font-size: 12px;
}

.ant-modal-content {
    border-radius: 1px !important;
}

.profile-pic {
    border-radius: 50%;
}

/* .my-profile-account{
    max-width: 80% !important;
} */

.home-page-layout {
    height: auto !important;
    overflow-y: auto !important;
    background-color: #F1F1F1;
}

.sso-login {
    width: 40px;
}

.sso-login-button {
    width: 150px !important;
    height: 40px !important;
    border-color: #da291c !important;
    background: #1B77AF;
}

.user-details-row {
    line-height: 40px;
}

.my-profile-tabs .ant-tabs-nav-wrap {
    font-weight: 700;
    font-family: 'Noto Sans', sans-serif;
}

.my-profile-tabs .ant-tabs-tab-active {
    color: #1B77AF;
    font-weight: 800 !important;
    font-family: 'Noto Sans', sans-serif;
}


.notification-dropdown-list.ant-list-item {
    padding-bottom: 5px !important;
}

.notification-dropdown-list .ant-list-item-meta-title {
    line-height: 15px !important;
    margin-bottom: 0px !important;
}

.approvals-table .ant-table-column-title {
    font-weight: 600;
    font-family: 'Noto Sans', sans-serif;
}

.approvals-table .ant-table {
    margin-right: 10px;
    border-radius: 0px;
}

.approvals-table thead[class*="ant-table-thead"] th {
    background-color: #F1F1F1 !important;
    border-radius: 0px;
}

.profile-pic-div .neo-btn {
    padding: 3px 6px !important;
}

.css-42igfv {
    margin: 20% !important;
    margin-left: 30% !important;
}

.loading-overall {
    /* width:1000%; */
    background-color: white !important;
    width: 600px !important;
    height: 10px !important;
}

.overlay-spinner {
    background-color: white !important;
    /* width: 600px !important; */
    height: 10px !important;
    border-radius: 10px !important;
}

.loading-overlay {
    height: 100% !important;
}

.width-50 {
    width: 50px !important;
    transition: width 0.5s ease-in-out;
}

.width-100 {
    width: 100px !important;
    transition: width 0.5s ease-in-out;
}

.width-150 {
    width: 150px !important;
    transition: width 0.5s ease-in-out;
}

.width-200 {
    width: 200px !important;
    transition: width 0.5s ease-in-out;
}

.width-250 {
    width: 250px !important;
    transition: width 0.5s ease-in-out;
}

.width-300 {
    width: 300px !important;
    transition: width 0.5s ease-in-out;
}

.width-350 {
    width: 350px !important;
    transition: width 0.5s ease-in-out;
}

.width-400 {
    width: 400px !important;
    transition: width 0.5s ease-in-out;
}

.width-450 {
    width: 450px !important;
    transition: width 0.5s ease-in-out;
}

.width-500 {
    width: 500px !important;
    transition: width 0.5s ease-in-out;
}

.width-550 {
    width: 550px !important;
    transition: width 0.5s ease-in-out;
}

.width-600 {
    width: 600px !important;
    transition: width 0.5s ease-in-out;
}

/* .clear-all-notification{
    display: flex;
    flex-direction: row-reverse
} */

.notification-dropdown-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: space-between;
    /* background: #F1F1F1 ; */
    color: #242424;
    font-size: 12px;
    padding: 10px;
}


.clear-all {
    position: relative;
    left: 1000px;
    /* -webkit-animation: slideIn 2s forwards; */
    /* -moz-animation: slideIn 2s forwards; */
    animation: slideIn 1s forwards;
    /* animation-iteration-count: 1;  */
}

@-webkit-keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
}

@-moz-keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
}

.portal-guide-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.tour-images {
    max-width: 100%;
    max-height: 100%;
}

.right-div-no-schedules-img {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}


/* .tour-guide-modal {
    width: 60% !important;
    height: 100% !important;
} */

/* .ant-modal, .ant-modal-content {
    height:600px;
    width: 900px;
    right: 60px;
    top: 10px;
   }
    */
/* .ant-modal-body {
    height: 500px;
   } */

.ant-list-empty-text {
    display: none;
}

.dropdown-notification-empty {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: auto;
    text-align: center !important;
    justify-content: center;
    flex-direction: column;
}

.ant-dropdown-menu-actions ::marker {
    font-size: 0px;
}

.no-top-margin-divider.ant-divider-horizontal {
    margin-bottom: 0 !important
}

.zero-margin-divider.ant-divider-horizontal {
    margin-bottom: 0 !important;
    /* margin-top: 0 !important */
}

.grey-divider.ant-divider-horizontal {
    background-color: rgb(149, 142, 142);
}

.ant-tag-checkable-checked {
    background-color: #125ab3;
}

.ant-tag-checkable-checked:hover {
    background-color: #125ab3;
}

.most-schedule-demos-filter {
    background-color: white;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 10px 10px 5px #7d868e3d;
}

.notification-banner-dashboard {
    box-shadow: 5px 5px 10px #7d868e3d;
}

.notification-banner-dashboard-close {
    text-align: center;
    font-weight: 800 !;
    height: 48px !important;
    line-height: 35px;
    overflow: hidden;
    background: #FFD79B;
    font-family: 'Noto Sans', sans-serif;
}

.centered-modal .ant-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.renderTabExtraOptions.ant-dropdown-menu {
    padding: 1px !important;
    background-color: #f4f3f3;
}

.tabbed-layout-tab-title {
    font-size: 14px;
}

.search-image {
    max-width: 120px;
    max-height: 120px;
}

.approval-div {
    display: flex;
    flex-direction: column;
    color: #242424;
    padding: 16px 8px;
}

.approvals-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 8px;
}

.approval-details-column {
    flex: 0 0 calc(33.33% - 10px) !important;
    padding: 2px !important;
    gap: 16px !important;
    /* max-width: 33.3% !important; */
    /* border: 1px solid black; */
}

.approvals-collapse.ant-collapse {
    background-color: white;
}

.approvals-collapse .ant-collapse-header {
    margin-left: -5px;
    width: 200px;
}

.approvals-action-button {
    top: 23px;
}

.approvals-collapse.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0px 16px 6px 16px;
    ;
    padding-left: 40px;
}

.approvals-collapse .ant-collapse-content {
    margin-left: -15px;
    color: #242424;

}

.approvals-collapse .ant-collapse-content-box {
    margin-left: 15px;
    padding: 0px 10px 10px 10px;
}

.approvals-hide-header .ant-collapse-header {
    display: none;
}

/* .ant-collapse>.ant-collapse-item {
    border-bottom: 0px !important
} */

.collapse-toggle {
    cursor: pointer;
}

.collapse-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 1.3s ease;
}

.collapse.open .collapse-content {
    max-height: 800px;
    /* Adjust this value to fit your content */
}

.float-right-flex {
    display: flex;
    flex-direction: row-reverse;
}

.approval-details-card {
    margin: 10px 0 10px 0;
    border: 2px solid #DDD;
}

.neo-link {
    color: #1B77AF !important;
    cursor: pointer !important;
    border: none !important;
    background: inherit !important;
}

.approval-step {
    background-color: #e9e7e74a;
    padding: 20px;
    margin-top: 15px !important;
}

.action-neo-button {
    margin-right: 2px;
}

/* Override Ant Design table styles */
.ant-table {
    font-size: 14px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: hidden;
}

.ant-table thead>tr>th {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e8e8e8;
    font-weight: bold;
}

.ant-table tbody>tr>td {
    border-bottom: 1px solid #e8e8e8;
}

.ant-table-row:hover>td {
    background-color: #f9f9f9;
}

.ant-table-row-selected>td {
    background-color: #e6f7ff;
}

/* Override Ant Design pagination styles
.ant-pagination {
  margin-top: 16px;
  text-align: right;
} */

/* .neo-pagination__row {
    margin: 0 8px;
} */

.neo-pagination__row {
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;
    color: #000 !important;
    margin-top: 8px !important;
}

.min-height-modal .neo-modal__body {
    min-height: 290px;
}

.min-height-modal .ant-modal-body {
    min-height: 375px;
}

.neo-pagination__list .neo-btn-square {
    width: 30px !important;
    height: 30px !important;
}

.ant-drawer-mask {
    left: 5px;
    background-color: rgba(0, 0, 0, 0.127);
}

.filter-drawer .ant-drawer-close {
    display: none;
}

.filter-drawer>.ant-drawer .ant-drawer-footer {
    border-top: 0px !important;
    padding: 0 16px !important;
}

.filter-drawer .ant-drawer .ant-drawer-body {
    padding: 12px 24px;
}

.filter-drawer .ant-drawer .ant-drawer-header {
    border-bottom: 0px !important;
    padding: 12px 24px !important;
}

.section {
    flex: 0.8;
}

.section-80 {
    height: 50%;
    background-color: #f2f2f2;
    /* Replace with your desired background color */
}

.section-20 {
    height: 10%;
    background-color: #ffffff;
    /* Replace with your desired background color */
}

/*   
  .approvals-pagination-row {
    display: flex !important;
    flex-direction: column !important;
    position: relative !important;
  }

.approval-pagination{
  position: fixed; */
/* top: 0; */
/* background-color: #d11414;
  width: 100%;
  bottom: 10px;
  padding-right: 5px !important;

  } */

.approvals-pagination-row {
    position: relative;
    overflow-y: auto;
    margin: 0;
    padding: 0;
}

.approval-pagination {
    position: sticky;
    /* left: 0; */
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
}


/* CSS */
.approval-details-table .ant-table {
    margin: 10px 0px 10px 0px;
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
}

/* CSS */
.approval-details-table .ant-table .ant-table-tbody {
    background-color: #fafafa !important;
}

.approval-details-table .ant-table-thead th {
    border-top: 1px solid #C9C9C9 !important;
    border-bottom: 1px solid #C9C9C9 !important;
    border-right: none !important;
    border-radius: 0 !important;
}

.approval-details-table .ant-table-cell {
    border-right: none !important;
}

.items-on-either-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.neo-button-selected {
    border-width: 1px !important;
    font-weight: 400 !important;
    color: #003A51 !important;
    border-color: #003A51 !important;
    background: var(--light-grey) !important;
}

/* Antd to Neo Table CSS */
.antd-to-neo-table .ant-table-thead th {
    border-top: 1px solid #C9C9C9 !important;
    border-bottom: 1px solid #C9C9C9 !important;
    border-right: none !important;
    border-radius: 0 !important;
    background-color: white !important;

}

.antd-to-neo-table .ant-table {
    /* border:; */
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
}

.antd-to-neo-table-report .ant-table-bordered .ant-table-thead>tr>th,
.antd-to-neo-table-report .ant-table-bordered .ant-table-tbody>tr>td,
.antd-to-neo-table-report .ant-table-bordered .ant-table-footer>tr>td {
    border: 1px solid #C9C9C9 !important;
}

.antd-to-neo-table .ant-table-column-title {
    font-size: 15px;
    font-weight: 600;
}

.antd-to-neo-table .ant-table tbody tr:hover td,
.table tbody tr:hover th {
    background-color: #f1f1f1 !important;
}

/* Custom.css */

.antd-to-neo-table .ant-table thead th {
    text-align: left !important;
}

.antd-to-neo-table-report .ant-table thead th {
    text-align: center !important;
}

.antd-to-neo-table-report th.ant-table-cell::before {
    display: none !important;
}

.antd-to-neo-table .ant-table-column-title {
    font-size: 14px;
    font-weight: 600;
}

.my-schedules-table-actions-menu .ant-dropdown-menu {
    border-radius: 0px !important;
}

.advanced-filtering-panel {
    display: flex;
    background-color: #f1f1f1 !important;
}

/* styles.css (or any other appropriate CSS file) */

.contact-card {
    display: flex;
    align-items: center;
    /* border: 1px solid #ccc; */
    padding: 2px;
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
}

.contact-details {
    min-width: 250px;
}

.floating-button-container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    justify-content: space-between
}

.scheduler-button {
    display: flex;
    flex-direction: row-reverse;
}



/* Admin Dashboard */

.admin-dashboard-approvals-empty {
    box-sizing: border-box;
    text-align: center !important;
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 0px;
    gap: 10px;
    width: auto;
    height: 400px;
    background-color: #eaeaea42;
    border: 1px solid #d3d3d3;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Noto Sans', sans-serif;
}

.dashboard-daily-testing {
    padding-left: 10px;
    color: black;
    line-height: 30px;
    font-size: 14px !important;
    /* font-weight: 600 ;    */
}

.dashboard-daily-testing-row {
    display: flex;
    justify-content: flex-end
        /* font-weight: 600 ;    */
}

.admin-dashoard-requests-collapse .ant-collapse-content-box {
    color: #000000d9;
    padding: 0 0 0 9px !important;

}

.admin-dashoard-requests-collapse .ant-collapse-arrow {
    display: none !important;
}

.admin-dashoard-requests-collapse {
    /* margin: 10px 10px 10px 5px; */
    background: white !important;
}

.admin-dashoard-requests-collapse .ant-collapse-header {
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 10px !important;
    cursor: default !important;
}

.success-item {
    color: green !important;
}

.admin-dashboard-request-user-response .ant-radio-wrapper,
.admin-dashboard-request-user-response .ant-checkbox-wrapper {
    font-size: 12px !important;
}

.admin-dashboard-request-user-response .ant-form-item-control-wrapper {
    margin: -5px !important;
}


.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    padding: 2px 9px !important;
}

.small-switch {
    width: 10px !important;
}

.testing-recipients {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.testing-mode {
    border-top: 15px solid pink;
    border-bottom: 15px solid pink;
    border-image: repeating-linear-gradient(-55deg,
            #000,
            #000 20px,
            #ffb101 20px,
            #ffb101 40px) 10;
}

.grey-text {
    color: #242424 !important;
}

.light-grey-text {
    color: #323232 !important;
}

.social-media-icon {
    width: 25px;
}

.social-media-icon-twitter {
    width: 25px;
    height: 24px;
}

.social-media-span {
    padding: 0 10px 0 10px;
}

.social-media-span-2 {
    padding: 0 50px 20px 0px;
}

.center-align-flex {
    display: flex;
    align-items: center;
}


.popular-ez-demos {
    display: flex;
    /* Display children in a row */
    justify-content: space-between;
    /* Add space between the cards */
}

.ez-demo-dashboard-scrollable-div {
    display: flex;
    padding: 10px;
    margin: 0 10px 0 10px;
    list-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    transition: all 1s;
    scroll-behavior: smooth;
}

.ez-demo-dashboard-scrollable-div::-webkit-scrollbar {
    display: none;
}

.ez-demo-dashboard-card {
    display: flex;
    /* flex-direction: column; */
    min-width: 50% !important;
    border-radius: 2px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 15%);
    scroll-snap-align: start;
    transition: all 0.5s;
    position: relative;
    width: 50%;
    /* Adjust the width as needed */
    border: 1px solid #ccc;
}


.ez-demo-dashboard-card .ant-col-1 {
    padding: 0px !important;
}


.ez-demo-dashboard-card-col2 {
    padding: 10px;
    /* margin: 10px; */
}

.ez-demo-dashboard-card-border {
    width: 15px;
    height: 100%;
    object-fit: cover;
}

.ez-demo-dashboard-card:not(:last-child) {
    margin-right: 10px;
}

.ez-demo-dashboard-card:hover {
    background: #F1F1F1;
}

.ez-demo-dashboard-card .ez-demo-dashboard-card-title {
    font-size: 14px;
    font-weight: 600;
}

.ez-demo-dashboard-card .ez-demo-dashboard-card-content {
    max-width: 100%;
    width: 95%;
    /* Set the desired width for your div */
    height: 1.5em;
    /* Set the desired max height (2 lines + ellipsis) */
    overflow: hidden;
    /* Hide any overflowing content */
    text-overflow: ellipsis;
    /* Show ellipsis for extra text */
    display: -webkit-box;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
}

.ez-demo-dashboard-card .ez-demo-dashboard-card-content-with-rating {
    max-width: 100%;
    width: 95%;
    /* Set the desired width for your div */
    height: 6em;
    /* Set the desired max height (2 lines + ellipsis) */
    overflow: hidden;
    /* Hide any overflowing content */
    text-overflow: ellipsis;
    /* Show ellipsis for extra text */
    display: -webkit-box;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
}

.ez-demo-dashboard-card .ez-demo-dashboard-card-link-wrapper {
    margin-top: auto;
}

.ez-dashboard-start-button {
    position: absolute;
    /* Position the fixed div */
    bottom: 0;
    right: 0;
    padding: 10px !important;
    /* padding: 10px; */
}

.ez-demo-dashboard-card .ez-demo-dashboard-card-link {
    display: block;
    text-decoration: none;
    color: white;
    background: #1379f6;
    padding: 6px 8px;
    border-radius: 2px;
    transition: background 0.2s;
    float: right;
}

.ez-demo-dashboard-card:hover .ez-demo-dashboard-card-link {
    background: #901b12;
}

.dashboard-ez-demo {
    display: flex;
    align-items: center
}

.left-scroll-arrows-ez-demo {
    position: absolute;
    opacity: 70%;
    left: 0;
    z-index: 1000;
}

.right-scroll-arrows-ez-demo {
    position: absolute;
    right: 0;
    opacity: 70%;
}

.dashboard-stats-type-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
}

.dashboard-stats-type-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    padding-left: 10px;
}

.admin-dashboard-request-tag {
    color: #2e34d1b3;
    background: #f0f7ff;
    border-color: #6375e0a1;
    border-radius: 1px;
}

.dashboard-stats-pending {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding-top: 5px;
    color: gray;
}

.stats-card-row1 {
    flex: 1;
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
}

.stats-card-row2 {
    flex: 4;
    border-radius: 4px;
    font-size: 26px;
    border: 1px;
    padding-left: 10px;
    padding-right: 10px;
}

.request-stats-card {
    height: 100px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 5px !important;
    border-radius: 4px;
    border: 1px;
    gap: 16px;
    color: black;
    background: linear-gradient(0deg, #F1F1F1, #F1F1F1),
        linear-gradient(0deg, #939393, #939393);
    border: 1px solid #939393;
    transition: transform 0.2s;
    /* Add a transition for smooth scaling */
}

.request-stats-card-link:hover {
    transform: scale(1.05);
    /* Zoom in by 10% on hover */
}

.stats-card-spacing {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px, 0px, 0px, 1px;
    gap: 8px
}

.welcome-dev {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d2f44;
}

#welcome-page {
    display: flex;
    justify-content: center;
    align-items: center;
}

#welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#welcome-h3 {
    color: white;
    font-size: 20px;
}

#welcome-ring {
    width: 590px;
    height: 590px;
    border: 1px solid transparent;
    border-radius: 50%;
    position: absolute;
}

#welcome-ring:nth-child(1) {
    border-bottom: 18px solid rgb(255, 141, 249);
    animation: rotate1 2s linear infinite;
}

@keyframes rotate1 {
    from {
        transform: rotateX(50deg) rotateZ(110deg);
    }

    to {
        transform: rotateX(50deg) rotateZ(470deg);
    }
}

#welcome-ring:nth-child(2) {
    border-bottom: 18px solid rgb(255, 65, 106);
    animation: rotate2 2s linear infinite;
}

@keyframes rotate2 {
    from {
        transform: rotateX(20deg) rotateY(50deg) rotateZ(20deg);
    }

    to {
        transform: rotateX(20deg) rotateY(50deg) rotateZ(380deg);
    }
}

#welcome-ring:nth-child(3) {
    border-bottom: 18px solid rgb(0, 255, 255);
    animation: rotate3 2s linear infinite;
}

@keyframes rotate3 {
    from {
        transform: rotateX(40deg) rotateY(130deg) rotateZ(450deg);
    }

    to {
        transform: rotateX(40deg) rotateY(130deg) rotateZ(90deg);
    }
}

#welcome-ring:nth-child(4) {
    border-bottom: 18px solid rgb(252, 183, 55);
    animation: rotate4 2s linear infinite;
}

@keyframes rotate4 {
    from {
        transform: rotateX(70deg) rotateZ(270deg);
    }

    to {
        transform: rotateX(70deg) rotateZ(630deg);
    }

}

.neo-scheduler-form .ant-calendar-picker-input.ant-input,
.neo-scheduler-form .ant-calendar-picker-input.ant-input:hover {
    border: 1px solid var(--input-border-color);
    font-size: 14px !important;
    color: #323232 !important;
    min-height: 36px;
    height: 36px;
    /* width: 180px */
}

.neo-scheduler-form .ant-calendar-picker-icon svg,
.neo-scheduler-form .ant-time-picker-clock-icon svg {
    color: #323232 !important;
    font-size: 14px !important;
}

.antd-neo-date-picker .ant-calendar-picker-input .ant-input-lg {
    height: 36px !important;
    margin-top: 1px !important;
    color: #323232 !important;
    font-size: 14px !important;
}

.neo-scheduler-form .ant-select-show-search .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--input-border-color);
    border-radius: 2px !important;
    color: #323232 !important;
    font-size: 14px !important;
    min-height: 36px;
}

.neo-scheduler-form .ant-select.ant-select-lg.ant-select-borderless.organiser-select.ant-select-multiple.ant-select-show-arrow.ant-select-show-search {
    border: 1px solid var(--input-border-color);
    border-radius: 2px !important;
    /* min-height: 36px; */
    color: #323232 !important;
    font-size: 14px !important;
    /* height: 36px !important; */
}

.neo-scheduler-form .ant-select-selection-item-content {
    font-size: 14px !important;
    color: #242424;
}

.neo-scheduler-form .ant-form-item-label label {
    letter-spacing: 0;
    color: var(--input-label-color);
    padding-bottom: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.neo-scheduler-form .ant-form-item-label>label::after {
    display: none;
}

.neo-scheduler-form .ant-picker-range {
    border: 1px solid var(--input-border-color);
    /* width: 180px; */
    border-radius: 2px;
}

.scheduler-range-picker {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 1rem;
    /* padding: 5px; */
    /* gap: 10px; */
    padding-top: 10px;
}

.scheduler-range-picker .ant-date-picker,
.scheduler-range-picker .ant-time-picker {
    width: 100%;
}

.scheduler-range-picker .ant-date-picker .ant-calendar-picker-container,
.scheduler-range-picker .ant-time-picker .ant-time-picker-input {
    border: 1px solid var(--input-border-color);
    border-radius: 2px !important;
    padding: 0.5rem;
}

.scheduler-range-picker .ant-picker {
    border: 1px solid var(--input-border-color);
    border-radius: 2px !important;
    min-height: 36px;
}

.scheduler-range-picker .ant-form-item-label label {
    letter-spacing: 0;
    color: var(--input-label-color);
    padding-bottom: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.scheduler-range-picker .ant-form-item-label>label::after {
    display: none;
}

.schedule-demo-title {
    font-family: Noto Sans;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #242424;
}

.banner {
    width: 100%;
    /* background-color: black;
    color: white; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;
}

.banner-content {
    width: 100%;
    /* margin: 0 auto; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.banner-left {
    /* width: 23%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.banner-right {
    /* width: 23%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding-left: 50px; */
    flex-wrap: wrap;
    align-content: flex-start;
}

.banner-right-content {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
}

.banner-middle {
    margin-left: 35px;
    margin-right: 35px;
}

.date-banner-left {
    font-family: Noto Sans;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: var(--base-900, #242424);
}

.day-banner-left {
    color: var(--base-900, #242424);
    /* Web/Body - Semibold */
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.banner-separator {
    width: 2px;
    height: 70px;
    background-color: #C9C9C9;
    opacity: 0.6;
    margin-left: 30px;
    margin-right: 30px;
}

/* .banner-seperator-parent {
    width: 6%;
    display: flex;
    justify-content: flex-start;
} */


.banner-content .banner-demo-time {
    font-family: Noto Sans;
    font-size: 19px !important;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    color: #242424;
    text-align: left;
}

.banner-content .banner-demo-name {
    font-family: Noto Sans;
    font-size: 14px !important;
    font-weight: 600;
    line-height: 20px;
    color: #242424;
    letter-spacing: 0em;
    text-align: left;
}


.banner-content .banner-demo-buffer {
    font-family: Noto Sans;
    font-size: 14px;
    color: #242424;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.schedule-details {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #C9C9C9, #C9C9C9);
    border: 1px solid #C9C9C9 !important;
    padding: 6px !important;
    border-radius: 0px, 4px, 4px, 4px !important;
    border: 1px;
    gap: 16px !important;
}

.resource-separator {
    width: 400%;
    height: 1px;
    background-color: #C9C9C9;
    opacity: 1;
    margin: 10px 10px;
    display: flex;
}

.resource-heading {
    font-family: Noto Sans;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.resource-content {
    padding: 16px;
    border-radius: 0px, 4px, 4px, 4px;
    border: 1px !important;
    gap: 16px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
        linear-gradient(0deg, #C9C9C9, #C9C9C9);
    border: 1px solid #C9C9C9 !important;
    display: flex;
    flex-direction: column;
}

.two-columns-flex {
    display: flex;
    flex-wrap: wrap;
}

.column-resource {
    width: 48%;
    margin-bottom: 1em;
    box-sizing: border-box;
}

.two-column-radio-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: #242424;
    gap: 8px 48px;
}

.two-column-radio {
    border-bottom: 1px solid #c9c9c9;
    margin: 4px;
    color: #242424;
    padding: 8px;
}

.ant-select-item-empty {
    color: #242424;
    padding: 8px 16px !important;
}

.confirmation-modal-details {
    font-family: Noto Sans;
    font-size: 14px !important;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.date-time-separator {
    margin: 0 10px;
    border: 1px solid #00000026;
    height: 20px;
}

.modal-date-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: Noto Sans;
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
}

.modal-title {
    font-family: Noto Sans;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.no-resource-div {
    display: flex;
    flex-direction: column;
    /* gap: 24px; */
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #C9C9C9, #C9C9C9);
    border: 1px solid #C9C9C9 !important;
    border-radius: 0px, 4px, 4px, 4px !important;
    border: 1px;
    height: 100%;
    width: 100%;
}

.no-resource-icon {
    font-size: 90px;
    align-items: center;
    margin: 0 auto;
    color: #C9C9C9;
}

.no-resource-text {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 auto;
    color: #323232;
    margin-bottom: 40px;
    margin-left: 60px;
    margin-right: 60px;
}

#dayOfWeek-label::before {
    content: "*";
    color: #b51418 !important;
    font-size: 12px !important;
    margin-right: 2px;
    padding-top: 2px !important;
}

.radio-div-occurence {
    border: 1px solid #c9c9c9;
    padding: 8px 16px;
    border-radius: 4px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: white !important;
    border: 4px solid #088A08 !important;
}

.occurence-panel {
    margin-bottom: 16px;
    border: 1px solid #939393 !important;
    border-radius: 4px !important;
    color: #242424;
}

.occurence-panel .ant-collapse-header:hover {
    background: #f1f1f1 !important;
}

.occurence-collapse.ant-collapse>.ant-collapse-item {
    border: 1px solid #939393 !important;
}

.occurence-collapse.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    color: #717171 !important;
    font-size: 14px !important;
}

.occurence-collapse.ant-collapse>.ant-collapse-item.ant-collapse-item-active {
    background: #d3d3d316 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #088A08 !important;
    border-color: #088A08 !important;
}

.ant-steps-icon .ant-steps-finish-icon {
    color: white !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #088A08 !important;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #088A08 !important;
}

.occurence-resource-body {
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    padding-bottom: 12px;
}


.conflicting-modal .neo-modal__content {
    width: 60% !important;
}

.conflicting-modal-2 .neo-modal__content {
    width: 75% !important;
}

.confirmation-modal-neo .neo-modal__content {
    width: 45% !important;
}

.confirmation-modal-neo-2 .ant-modal {
    width: 700px !important;
}

.confirmation-modal-neo-2 .ant-modal-header {
    /* width: 55% !important; */
    border-bottom: none !important;
    text-align: center;
}

.confirmation-modal-neo-2 .ant-modal-footer {
    border-top: none !important;
}

.confirmation-modal-neo-2 .ant-modal-title {
    font-size: 19px !important;
    line-height: 28px !important;
}

.reccurence-confirmation-warning {
    border: 1px solid #b35c00;
    border-left: 5px solid #b35c00;
    border-radius: 4px;
    background-color: #fff7eb7f;
    color: #000;
    height: 66px;
    display: flex;
    align-items: center;
    width: 480px;
    font-size: 14px;
    font-weight: 400;
    padding: 16px;
}

.time-available-check {
    font-size: 14px;
    margin-top: 20px;
}

.lab-confirm-modal .neo-modal__content {
    width: 40% !important
}

.assistant-modal .neo-modal__content {
    width: 80% !important
}

.banner-info-title {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #242424;
}

.confirmation-banner {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: center;
}

.confirmation-container {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--base-200, #C9C9C9);
}

.batch-delete-confirmation-warning {
    border: 1px solid #D93D32 !important;
    border-left: 5px solid #D93D32 !important;
    border-radius: 4px;
    background: linear-gradient(0deg, #FCECEB, #FCECEB),
        linear-gradient(0deg, #D93D32, #D93D32);
    color: #000;
    height: 66px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}

.highlight-collapse {
    background-color: #f84a4a !important;
    transition: background-color 0.5s;
}

.scheduler-help .ant-badge .ant-badge-dot {
    background: #da291c;
}

.scheduler-help .ant-float-btn-primary .ant-float-btn-body {
    background-color: #1B77AF;
    box-shadow: rgba(24, 24, 27, 0.35) 0px 5px 15px;
}

.dashboard-demo-stats-rows {
    display: table-cell;
    padding-left: 8px;
    padding-right: 8px;
}

.dashboard-demo-stats-col {
    display: table-row;
    align-items: baseline;
    gap: 16px;
    align-self: stretch;
}

.demo-stats-view-details.ant-modal {
    width: 90% !important;
    top: 5px !important;
}

.demo-stats-view-details .ant-modal-title {
    display: flex;
    justify-content: center;
}

.demo-stats-view-details .anticon svg {
    display: none;
}

.demo-stats-view-details .ant-modal-header {
    border-bottom: 0px;

}

.demo-stats-view-details .ant-modal-footer {
    border-top: 0px;
}

.dashboard-demo-stats-modal-wrap {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

.demo-stats-view-details .ant-modal-content {
    border-radius: 4px !important;
    box-shadow: 0 8px 16px #00000040;
    margin: 16px;
    padding: 24px;
}

.demo-stats-not-tested {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.daily-test-demo-stats {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #C9C9C9;
    gap: 8px;
}

.demo-stats-separator {
    width: 2px;
    height: auto;
    background-color: #949494;
    opacity: 0.6;
    margin-left: 10px;
    margin-right: 10px;
}

.dashboard-daily-testing-demo-stats {
    display: table;

    font-family: Noto Sans;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.demo-stats-sub-head {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.demo-stats-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.demo-stats-child-left {
    grid-column: 1;
}

.demo-stats-child-right {
    grid-column: 2;
    /* Doesn't grow, maintaining initial width */
    margin-left: 16px;
}

.pink-chip {
    border: 1px solid #BF73E5 !important;
    background: #F7EDFC !important;
}

.reports-header-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 8px;
}

.view-reports-title {
    font-family: Noto Sans;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

}

.ez-demo-description {
    font-size: 12px;
    color: #5E5E5E !important;
}

.dashboard-requests-sub-heading {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #242424;
}

.user-news-heading {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #242424;
    margin: 5px 0 5px 8px;
}

.straight-line {
    width: 35px;
    height: 2.5px;
    background-color: #2C3E50;
    margin: 8px;
}

.dotted-line {
    width: 35px;
    height: 2.5px;
    margin: 8px;
    border-bottom: 2.5px dashed #2C3E50;
}

.buffer-tooltip {
    z-index: 9999 !important;
}

.demo-document-type {
    font-family: Noto Sans;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: left;
    color: #242424;
}

.demo-document-title {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #1B77AF !important;
}

.demo-document-description {
    color: #5E5E5E !important;
    font-family: Noto Sans;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: left;
}

.demo-document-div {
    padding: 0px 0px 8px 0px !important;
    gap: 8px !important;
    border: 0px 0px 1px 0px !important;
    border-bottom: 1px solid #DDDDDD !important;
    display: flex;
    flex-direction: column;
}

.demo-document-date {
    font-family: Noto Sans;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: left;
    color: #5E5E5E;
}

.custom-tooltip .ant-tooltip-inner {
    position: relative;
    background-color: rgba(0, 0, 0, 0.9);
}

.custom-tooltip .ant-tooltip-inner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
    z-index: -1;
}

.rating-author-title {
    color: #5E5E5E;
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.rating-comment {
    color: #242424 !important;
    font-family: Noto Sans;
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    text-align: left;
}

.avg-rating {
    color: #242424;
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
}

.rating-reply-box {
    padding: 16px 16px 16px 16px !important;
    gap: 24px !important;
    border: 1px solid #C9C9C9 !important;
    border-radius: 4px !important;
}

.no-data .neo-icon-info {
    color: #242424 !important;
}


.antd-to-neo-table .ant-table-body {
    font-size: 14px !important;
}

.antd-to-neo-table .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: #1B77AF;
}

.rating-section {
    display: flex;
    align-items: center;
    padding: 24px 16px 16px 16px !important;
    gap: 24px !important;
}

.rating-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rating-details {
    display: flex;
    gap: 8px !important;
}

.rating-average-demo-review {
    font-size: 32px;
    color: #242424;
}

.rating-average-demo-review svg {
    height: 21px !important;
    width: 21px !important;
}

.ez-demo-review-drawer .ant-drawer-body {
    padding: 8px !important;
}

.approval-decline-button:hover {
    border: 1px solid #da291c;
    border-radius: 2px;
    color: #da291c;
}

.demo-status-tabs .ant-tabs-nav .ant-tabs-tab {
    min-width: 150px !important;
    text-align: center !important;
}

.demo-status-tabs .ant-tabs-tab-active {
    color: #1b77af !important;
    font-weight: 600 !important;
}


.demo-status-tabs .ant-tabs-tab:hover {
    color: #155e8b !important;
    cursor: pointer;
    text-decoration: underline;
    transition: color .4s;
    background-color: #f1f1f1;
    transition: background-color .4s;
}

.demo-status-tabs>.ant-tabs-bar.ant-tabs-top-bar {
    border-bottom: 1px solid var(--Base-200, #C9C9C9);
}

.demo-status-tabs .ant-tabs-tab-disabled {
    cursor: not-allowed !important;
    color: #808080b0 !important;
}

.demo-status-tabs .ant-tabs-tab-disabled:hover {
    cursor: not-allowed !important;
    color: #808080b0 !important;
    text-decoration: none !important;
    background-color: none !important;

}

#pop-up-cancel {
    background-color: var(--button-tertiary-hover-background-color);
    background-image: linear-gradient(50deg, var(--button-tertiary-hover-background-color) 0%, var(--button-tertiary-hover-background-color) 50%, var(--button-secondary-background-color) 50%, var(--button-secondary-background-color) 100%);
    border-color: #1b77af;
    color: #1b77af;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 300%;
    transition: background-position .4s;
    border-style: solid;
    border-width: 1px;
    height: 37px;
}

#pop-up-cancel:hover {
    border-color: #155e8b;
    color: #155e8b;
    cursor: pointer;
    background-position: 0 100%;
    transition: background-position .4s, border-color .4s .15s, color .4s;
}

#pop-up-confirm {
    background-color: var(--button-hover-color-default-info);
    color: var(--button-text-color);
    background-image: linear-gradient(50deg, var(--button-hover-color-default-info) 0%, var(--button-hover-color-default-info) 50%, var(--button-color-primary) 50%, var(--button-color-primary) 100%);
    background-position: 100% 0;
    background-repeat: no-repeat;
    height: 37px;
    background-size: 300%;
    transition: background-position .4s;
}

#pop-up-confirm:hover {
    border-color: #155e8b;
    color: #155e8b;
    cursor: pointer;
    background-position: 0 100%;
    transition: background-position .4s, border-color .4s .15s, color .4s;
}

.flexed-inputs>.neo-input-group label:not(.neo-switch):not(.neo-label) {
    display: flex !important;
}

.flexed-inputs {
    color: #242424 !important;
    padding: 0 8px !important;
    outline: none !important;
    border: none !important;
}

.schedular-account-owner {
    display: flex;
    border: 1px solid #DDDDDD;
    background: #F1F1F1;
    padding: 16px;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-right: 32px;
    width: 49%;
}

.select-disabled {
    background-color: #f1f1f1;
    color: #939393 !important;
}

.schedule-demo-menu-actions .neo-dropdown__content {
    min-width: 250px !important;
}

.ant-select-selection__choice {
    color: var(--chip-font-color) !important;
    background-color: var(--chip-default-color) !important;
    border: 1px solid var(--chip-default-border-color) !important;
    border-radius: 4px !important;
    font-size: 13px !important;
}